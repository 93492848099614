import {
  fetchPackagesAPI,
  fetchPackageAPI,
  createPackageAPI,
  updatePackageAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {
    packages: [],
  },
  getters: {
    packageOptions(state) {
      if (state.packages.length <= 0) {
        return []
      }
      return state.packages.map(obj => ({
        label: obj.description,
        value: obj.privilegeId,
      }))
    },
  },
  mutations: {
    SET_PACKAGE_OPTIONS(state, data) {
      state.packages = data
    },
  },
  actions: {
    async fetchPackages(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchPackagesAPI, { params: queryParams })
        ctx.commit('SET_PACKAGE_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchPackage(ctx, id) {
      try {
        const response = await this.$axios.get(`${fetchPackageAPI}/${id}`)
        return response
      } catch (error) {
        return error
      }
    },
    async createPackage(ctx, params) {
      try {
        const response = await this.$axios.post(createPackageAPI, params)
        return response
      } catch (error) {
        return error
      }
    },
    async updatePackage(ctx, params) {
      try {
        const response = await this.$axios.post(updatePackageAPI, params)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
