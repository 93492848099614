<template>
  <validation-observer
    #default="{ invalid, handleSubmit }"
  >
    <b-form
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="handleSubmit()"
    >

      <subscriptions-repeater-form
        v-for="(subscription, index) in packageData.subscriptions"
        :id="index"
        :key="index"
        ref="row"
        :subscription="subscription"
        :index="index"
        :tenure-options="tenureOptions"
        @remove-item="removeItem"
      />

    </b-form>
    <b-row class="mb-4">
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          size="sm"
          class="float-right"
          :disabled="invalid"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New</span>
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>

</template>

<script>
import {
  BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SubscriptionsRepeaterForm from '../subscriptions-form/SubscriptionsRepeaterForm.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,

    ValidationObserver,

    SubscriptionsRepeaterForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    packageData: {
      type: Object,
      required: true,
    },
    tenureOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nextId: 2,
      required,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.packageData.subscriptions.push({
        id: this.nextId += this.nextId,
      })

      this.$nextTick(() => {
        this.trAddHeight((this.$refs.row[0].$el.offsetHeight + 30))
      })
    },
    removeItem(index) {
      if (index == 0) {
        return
      }
      this.packageData.subscriptions.splice(index, 1)
      this.trTrimHeight((this.$refs.row[0].$el.offsetHeight + 30))
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight((this.$refs.form.scrollHeight + 30))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
