<template>
  <b-row>

    <!-- Package Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Package Name"
        rules="required"
      >
        <b-form-group
          label="Package Name"
          label-for="package-name"
        >
          <b-form-input
            id="package-name"
            v-model="packageData.name"
            :state="getValidationState(validationContext)"
            placeholder="Package Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Package Name AR -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Package Name AR"
        rules="required"
      >
        <b-form-group
          label="Package Name AR"
          label-for="package-name-ar"
        >
          <b-form-input
            id="package-name-ar"
            v-model="packageData.nameAr"
            :state="getValidationState(validationContext)"
            placeholder="Package Name AR"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="packageData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Description AR -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description AR"
        rules="required"
      >
        <b-form-group
          label="Description AR"
          label-for="description-ar"
        >
          <b-form-textarea
            id="description-ar"
            v-model="packageData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="Description AR"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Branch Limit -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Branch Limit"
        rules="required|integer"
      >
        <b-form-group
          label="Branch Limit"
          label-for="branch-limit"
        >
          <b-form-input
            id="branch-limit"
            v-model="packageData.branchesLimit"
            type="text"
            :state="getValidationState(validationContext)"
            placeholder="Branch Limit"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Cashiers Limit Per Branch -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Cashiers Limit Per Branch"
        rules="required|integer"
      >
        <b-form-group
          label="Cashiers Limit Per Branch"
          label-for="cashiers-limit-per-branch"
        >
          <b-form-input
            id="cashiers-limit-per-branch"
            v-model="packageData.cashiersPerBranchLimit"
            type="text"
            :state="getValidationState(validationContext)"
            placeholder="Cashiers Limit Per Branch"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- For Hala Cashier -->
    <b-col md="6">
      <validation-provider
        #default="validationContext"
        name="Package Is For?"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Package Is For?"
          label-for="for-hala-cashier"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="packageData.forHalaCashier"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="forHalaCashierOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="for-hala-cashier"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Status -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Status"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Status"
          label-for="status"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="packageData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="status"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- With Hardware -->
    <b-col sm="6">
      <b-form-group
        label="With Hardware"
        label-for="with-hardware"
      >
        <b-form-checkbox
          id="with-hardware"
          v-model="packageData.withHardware"
          :unchecked-value="0"
          :value="1"
          name="with-hardware"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
  BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,

    //  Form Validation
    ValidationProvider,

    vSelect,
  },

  directives: {
    Ripple,
  },
  props: {
    packageData: {
      type: Object,
      required: true,
    },
    forHalaCashierOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      //  Form
      getValidationState,
    }
  },
}
</script>
