import { ref, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import packageStoreModule from '../packageStoreModule'

export default function usePackagesForm() {
  const PACKAGE_APP_STORE_MODULE_NAME = 'app-package'

  // Register module
  if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.registerModule(PACKAGE_APP_STORE_MODULE_NAME, packageStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME)
  })

  const packageData = ref({
    name: null,
    nameAr: null,
    description: null,
    descriptionAr: null,
    withHardware: 0,
    branchesLimit: null,
    cashiersPerBranchLimit: null,
    agency: null,
    forHalaCashier: null,
    status: null,
    subscriptions: [
      {
        title: null,
        subTitle: null,
        titleAr: null,
        subTitleAr: null,
        price: null,
        tenure: null,
      },
    ],
  })

  const packages = computed(() => store.state['app-package'].packages)

  const packageOptions = computed(() => store.getters['app-package/packageOptions'])

  const packageSubscriptionOptions = computed(() => store.getters['app-package/packageSubscriptionOptions'])

  const fetchPackageSubscriptions = packageId => {
    if (packages.value && packages.value.length === 0) {
      return
    }

    const packageSubscriptions = packages.value.find(obj => packageId === obj.packageId)

    if (!packageSubscriptions) {
      return
    }

    if (packageSubscriptions && packageSubscriptions.subscriptions && packageSubscriptions.subscriptions.length === 0) {
      return
    }

    store.commit('app-package/SET_PACKAGE_SUBSCRIPTIONS_OPTIONS', packageSubscriptions.subscriptions)
  }

  const fetchPackages = async params => {
    try {
      const response = await store.dispatch('app-package/fetchPackages', params)
      return response
    } catch (error) {
      return error
    }
  }

  const fetchPackage = async () => {
    try {
      const response = await store.dispatch('app-package/fetchPackage', router.currentRoute.params.id)
      // eslint-disable-next-line prefer-destructuring
      packageData.value = response.data.data[0]
    } catch (error) {
      if (error.response.status === 404) {
        packageData.value = undefined
      }
    }
  }

  const createPackage = async () => {
    try {
      const response = await store.dispatch('app-package/createPackage', packageData.value)
      if (!response.data.status) {
        return
      }
      router.push({ name: 'general-settings-packages-list' })
    } catch (error) {
      console.error(error)
    }
  }

  const updatePackage = async () => {
    try {
      const response = await store.dispatch('app-package/updatePackage', packageData.value)
      if (!response.data.status) {
        return
      }
      router.push({ name: 'general-settings-packages-list' })
    } catch (error) {
      console.error(error)
    }
  }

  // TODO: Add to system codes
  const forHalaCashierOptions = [
    {
      label: 'Lite',
      value: 0,
    },
    {
      label: 'Main',
      value: 1,
    },
  ]

  return {
    //  Data
    packageData,

    //  Options
    packageOptions,
    packageSubscriptionOptions,
    forHalaCashierOptions,

    //  Actions
    fetchPackages,
    fetchPackage,
    fetchPackageSubscriptions,
    createPackage,
    updatePackage,
  }
}
