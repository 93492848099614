<template>
  <!-- Row Loop -->
  <div class="border rounded mb-2 p-2">
    <b-row>

      <!-- Remove Button -->
      <b-col
        md="12"
        class="mb-2"
      >
        <b-button
          v-if="index != 0"
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          class="float-right"
          size="sm"
          @click="removeItem(index)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>Delete</span>
        </b-button>
      </b-col>

      <!-- Title -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Title"
          rules="required"
        >
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="subscription.title"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="Title"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Title AR -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Title AR"
          rules="required"
        >
          <b-form-group
            label="Title AR"
            label-for="title-ar"
          >
            <b-form-input
              id="title-ar"
              v-model="subscription.titleAr"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="Title AR"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Sub Title -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="SubTitle"
          rules="required"
        >
          <b-form-group
            label="SubTitle"
            label-for="sub-title"
          >
            <b-form-input
              id="sub-title"
              v-model="subscription.subTitle"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="SubTitle"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Sub Title AR -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="SubTitle AR"
          rules="required"
        >
          <b-form-group
            label="SubTitle AR"
            label-for="sub-title-ar"
          >
            <b-form-input
              id="sub-title-ar"
              v-model="subscription.subTitleAr"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="SubTitle AR"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Price -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Price"
          rules="required"
        >
          <b-form-group
            label="Price"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="subscription.price"
              type="number"
              :state="getValidationState(validationContext)"
              placeholder="Price"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Tenure -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Tenure"
          rules="required"
        >
          <b-form-group
            class="mb-1"
            label="Tenure"
            label-for="tenure"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="subscription.tenure"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tenureOptions"
              class="w-100"
              :reduce="val => val.value"
              input-id="tenure"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,

    ValidationProvider,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    tenureOptions: {
      type: Array,
      required: true,
    },
  },
  setup(_, { emit }) {
    const removeItem = index => {
      emit('remove-item', index)
    }

    const {
      getValidationState,
    } = formValidation()

    return {
      removeItem,

      getValidationState,

    }
  },

}
</script>
